import { useQuery } from "@apollo/client";
import { GET_UTRZAK_LIST } from "../utils/graphqlQueries";
import { Label, Table, Loader } from 'semantic-ui-react'

const UtrzakList = () => {
    const { data, loading, error } = useQuery(GET_UTRZAK_LIST, {
        fetchPolicy: "no-cache"
    });

    if (error) return <h1>Error: {error.message}</h1>
    if (loading) return <Loader active inline='centered' />

    console.log("DATA", data && data)
    return (
        <Table celled className="custom-table">
            {data && data.getUtrzakList.length === 0 ? (
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            <Label>No data...</Label>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
            ) : (
                <Table.Header>
                    <Table.Row warning>
                        <Table.HeaderCell>Datum</Table.HeaderCell>
                        <Table.HeaderCell>Količina</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
            )}

            <Table.Body>
                {data?.getUtrzakList.map(({
                    id,
                    date,
                    amount
                }) => (
                    (
                        <Table.Row key={id}>
                            <Table.Cell>
                                <Label>{date}</Label>
                            </Table.Cell>
                            <Table.Cell>{amount}</Table.Cell>
                        </Table.Row>
                    )
                ))}
            </Table.Body>
        </Table>
    )
}

export default UtrzakList;