import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { useQuery, useMutation } from "@apollo/client";
import { DELETE_EARNING, GET_EARNING_BY_ID, UPDATE_EARNING, GET_USER_EARNINGS} from "../utils/graphqlQueries";
import { changeDateFormat } from "../utils/formatDate";
import { Header, Button } from "semantic-ui-react";

import './CreateSpending.css';

const EarningEdit = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { register, setValue, handleSubmit } = useForm()
    const { data, loading, error } = useQuery(GET_EARNING_BY_ID, {
        variables: { earningId: id },
        fetchPolicy: "no-cache"
    })
    const [updateEarning] = useMutation(UPDATE_EARNING)
    const [deleteEarning] = useMutation(DELETE_EARNING)

    useEffect(() => {
        if (data) {
            setValue('earningType', data.getUserEarningById.earningType)
            setValue('date', changeDateFormat(data.getUserEarningById.date))
            setValue('description', data.getUserEarningById.description)
            setValue('cash_total', data.getUserEarningById.cash_total)
            setValue('card_total', data.getUserEarningById.card_total)
            setValue('tax_total', data.getUserEarningById.tax_total)
        }
    }, [data, id])

    if (error) return <h2>Error: {error.message}</h2>
    if (loading) return <h2>Loading...</h2>

    const onSubmit = (formData) => {
        updateEarning({
            variables: { earningId: id, earningInput: { ...formData, date: changeDateFormat(formData.date) } },
            refetchQueries: [
                { query: GET_USER_EARNINGS }
            ],
        }).then(() => navigate(-1))
    }

    const handleDelete = () => {
        deleteEarning({
            variables: { objectId: id },
            refetchQueries: [
                { query: GET_USER_EARNINGS }
            ]
        })
        .then(() => navigate(-1))
    }

    return (
        <>
            <Header as='h3' dividing>
                Uredi Promet
            </Header>

            <form className="spending-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                    <label htmlFor="earningType">Tip:</label>
                    <select {...register('earningType')} required>
                        <option value="Bijelo">Bijelo</option>
                        <option value="Crno">Crno</option>
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="date">Datum:</label>
                    <input type="date" {...register('date')} required />
                </div>

                <div className="form-group">
                    <label htmlFor="description">Opis:</label>
                    <input type="text" {...register('description')} required />
                </div>

                <div className="form-group">
                    <label htmlFor="cash_total">Keš:</label>
                    <input type="number" step="any" {...register('cash_total', { valueAsNumber: true })} required />
                </div>

                <div className="form-group">
                    <label htmlFor="card_total">Kartica:</label>
                    <input type="number" step="any" {...register('card_total', { valueAsNumber: true })} required />
                </div>

                <div className="form-group">
                    <label htmlFor="tax_total">Ukupan PDV:</label>
                    <input type="number" step="any" {...register('tax_total', { valueAsNumber: true })} required />
                </div>

                <div className='button-div'>
                    <button type="submit">Spremi</button>
                </div>
            </form>

            <div className="delete-button-wrapper">
                <Button negative onClick={handleDelete}>
                    Obrisi
                </Button>
            </div>
        </>
    )
}

export default EarningEdit;