export function formatDate(input) {
    const date = input ?? new Date();
    const year = date.getFullYear();
    let month = date.getMonth() + 1;

    month = month < 10 ? '0' + month : month;

    return `${year}-${month}`;
}

export function changeDateFormat(dateStr) {
    const parts = dateStr.split('-');
    
    const year = parts[0];
    const month = parts[1];
    const day = parts[2];

    return `${year}-${day}-${month}`;
}
