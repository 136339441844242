import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { changeDateFormat } from '../utils/formatDate';
import { useMutation } from '@apollo/client';
import { CREATE_SPENDING, GET_USER_SPENDINGS } from '../utils/graphqlQueries';
import { Header } from 'semantic-ui-react';

import './CreateSpending.css';

const CreateSpending = () => {
    const navigate = useNavigate()
    const { register, handleSubmit } = useForm();
    const [createSpending, { loading, error }] = useMutation(CREATE_SPENDING, {
        refetchQueries: [GET_USER_SPENDINGS]
    })

    const onSubmit = (formData) => {
        createSpending({ variables: { spendingInput: { ...formData, date: changeDateFormat(formData.date) } } })
            .then(navigate(-1))
    }

    if (error) return <h1>Error: {error.message}</h1>
    if (loading) return <h1>Loading...</h1>


    return (
        <>
            <Header as='h3' dividing>
                Kreiraj Trošak
            </Header>

            <form className="spending-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                    <label htmlFor="spendingType">Tip:</label>
                    <select {...register('spendingType')} required>
                        <option value="Bijelo">Bijelo</option>
                        <option value="Crno">Crno</option>
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="paymentType">Način plaćanja:</label>
                    <select {...register('paymentType')} required>
                        <option value="Cash">Gotovinsko</option>
                        <option value="Card">Kartično</option>
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="date">Datum:</label>
                    <input type="date" {...register('date')} required />
                </div>

                <div className="form-group">
                    <label htmlFor="description">Opis:</label>
                    <input type="text" {...register('description')} required />
                </div>

                <div className="form-group">
                    <label htmlFor="regular_price">Osnovica:</label>
                    <input type="number" step="any" {...register('regular_price', { valueAsNumber: true })} required />
                </div>

                <div className="form-group">
                    <label htmlFor="total_price">Ukupno:</label>
                    <input type="number" step="any" {...register('total_price', { valueAsNumber: true })} required />
                </div>

                <div className="form-group">
                    <label htmlFor="tax">PDV:</label>
                    <input type="number" step="any" {...register('tax', { valueAsNumber: true })} required />
                </div>

                <div className='button-div'>
                    <button type="submit">Spremi</button>
                </div>
            </form>
        </>
    );
}

export default CreateSpending