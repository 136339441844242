import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Label, Table, Loader, Dropdown, Search, Icon } from 'semantic-ui-react'
import DatePicker from "react-datepicker";
import hr from 'date-fns/locale/hr';
import { useQuery } from "@apollo/client";
import { GET_USER_SPENDINGS } from "../utils/graphqlQueries";
import { formatDate } from "../utils/formatDate";

import "react-datepicker/dist/react-datepicker.css";

const Spendings = () => {
    const [startDate, setStartDate] = useState(() => {
        const storedDate = localStorage.getItem("date");
        return storedDate ? new Date(storedDate) : new Date();
    });
    const [spendingTypeFilter, setSpendingTypeFilter] = useState("");
    const [paymentTypeFilter, setPaymentTypeFilter] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const { data, loading, error } = useQuery(GET_USER_SPENDINGS, {
        variables: { date: formatDate(startDate) },
        fetchPolicy: "no-cache"
    })

    if (error) return <h1>Error: {error.message}</h1>
    if (loading) return <Loader active inline='centered' />

    const filteredData = data?.getUserSpendings?.filter((item) => {
        const spendingTypeMatch =
            !spendingTypeFilter || item.spendingType === spendingTypeFilter;
        const paymentTypeMatch =
            !paymentTypeFilter || item.paymentType === paymentTypeFilter;
        const searchMatch =
            !searchTerm ||
            item.description.toLowerCase().includes(searchTerm.toLowerCase());

        return spendingTypeMatch && paymentTypeMatch && searchMatch;
    });

    const handleDateChange = (date) => {
        setStartDate(date)
        localStorage.setItem("date", date)
    }

    return (
        <>
            <div className="datepicker-div">
                <DatePicker
                    locale={hr}
                    dateFormat="MM/yyyy"
                    selected={startDate}
                    onChange={(date) => handleDateChange(date)}
                    showMonthYearPicker
                    showFullMonthYearPicker
                />

                <Search
                    icon={
                        <>
                            {searchTerm ? (
                                <Icon
                                    name="delete"
                                    link
                                    onClick={() => setSearchTerm("")}
                                    color="grey"
                                />
                            ) : <Icon name="search" color="grey" />}
                        </>
                    }
                    value={searchTerm}
                    onSearchChange={(e) => setSearchTerm(e.target.value)}
                    showNoResults={false}
                    placeholder="Pretraži"
                />
            </div>

            <div className="filter-wrapper">
                <Dropdown
                    placeholder="Odaberi tip"
                    selection
                    simple
                    options={[
                        { key: "all", text: "Sve", value: "" },
                        { key: "bijelo", text: "Bijelo", value: "Bijelo" },
                        { key: "crno", text: "Crno", value: "Crno" },
                    ]}
                    value={spendingTypeFilter}
                    onChange={(e, { value }) => setSpendingTypeFilter(value)}
                    className="dropdown-width"
                />

                <Dropdown
                    placeholder="Vrsta plaćanja"
                    selection
                    simple
                    options={[
                        { key: "all", text: "Sve", value: "" },
                        { key: "cash", text: "Gotovina", value: "Cash" },
                        { key: "card", text: "Kartica", value: "Card" },
                    ]}
                    value={paymentTypeFilter}
                    onChange={(e, { value }) => setPaymentTypeFilter(value)}
                    className="dropdown-width"
                />
            </div>

            <Table celled className="custom-table">
                {data && data.getUserSpendings.length === 0 ? (
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                <Label>No data...</Label>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                ) : (
                    <Table.Header>
                        <Table.Row warning>
                            <Table.HeaderCell>Datum</Table.HeaderCell>
                            <Table.HeaderCell>Tip</Table.HeaderCell>
                            <Table.HeaderCell>Vrsta placanja</Table.HeaderCell>
                            <Table.HeaderCell>Opis</Table.HeaderCell>
                            <Table.HeaderCell>Osnovica</Table.HeaderCell>
                            <Table.HeaderCell>Ukupno</Table.HeaderCell>
                            <Table.HeaderCell>Pdv</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                )
                }

                <Table.Body>
                    {filteredData?.map(({
                        id,
                        date,
                        spendingType,
                        description,
                        regular_price,
                        total_price,
                        tax,
                        paymentType
                    }) => (
                        (
                            <Table.Row key={id}>
                                <Table.Cell>
                                    <Label>{date}</Label>
                                </Table.Cell>
                                <Table.Cell>{spendingType}</Table.Cell>
                                <Table.Cell>{paymentType}</Table.Cell>
                                <Table.Cell>{description}</Table.Cell>
                                <Table.Cell>{regular_price} €</Table.Cell>
                                <Table.Cell>{total_price} €</Table.Cell>
                                <Table.Cell>{tax} €</Table.Cell>
                                <Table.Cell><Link to={`/spendings/${id}`}>Uredi</Link></Table.Cell>
                            </Table.Row>
                        )
                    ))}
                </Table.Body>
            </Table>

            <Link to="/create-spending">
                <p className="ui inverted primary button big add-button">
                    +
                </p>
            </Link>
        </>
    )
}

export default Spendings;