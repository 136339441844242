import React, { useState } from "react";
import { Link } from 'react-router-dom'
import { Label, Table, Loader, Dropdown, Search, Icon } from 'semantic-ui-react'
import DatePicker from "react-datepicker";
import hr from 'date-fns/locale/hr';
import { useQuery } from "@apollo/client";
import { GET_USER_EARNINGS } from "../utils/graphqlQueries";
import { formatDate } from "../utils/formatDate";

import "react-datepicker/dist/react-datepicker.css";

const Earnings = () => {
    const [startDate, setStartDate] = useState(() => {
        const storedDate = localStorage.getItem("date");
        return storedDate ? new Date(storedDate) : new Date();
    });
    const [earningTypeFilter, setEarningTypeFilter] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const { data, loading, error } = useQuery(GET_USER_EARNINGS, {
        variables: { date: formatDate(startDate) },
        fetchPolicy: "no-cache"
    })

    // handle showing on screen and handle loading and error
    if (error) return <h1>Error: {error.message}</h1>
    if (loading) return <Loader active inline='centered' />

    const filteredData = data?.getUserEarnings?.filter((item) => {
        const earningTypeMatch =
            !earningTypeFilter || item.earningType === earningTypeFilter;
        const searchMatch =
            !searchTerm ||
            item.description.toLowerCase().includes(searchTerm.toLowerCase());


        return earningTypeMatch && searchMatch;
    });

    const handleDateChange = (date) => {
        setStartDate(date)
        localStorage.setItem("date", date)
    }

    return (
        <>
            <div className="datepicker-div">
                <DatePicker
                    locale={hr}
                    dateFormat="MM/yyyy"
                    selected={startDate}
                    onChange={(date) => handleDateChange(date)}
                    showMonthYearPicker
                    showFullMonthYearPicker
                />

                <Search
                    icon={
                        <>
                            {searchTerm ? (
                                <Icon
                                    name="delete"
                                    link
                                    onClick={() => setSearchTerm("")}
                                    color="grey"
                                />
                            ) : <Icon name="search" color="grey" />}
                        </>
                    }
                    value={searchTerm}
                    onSearchChange={(e) => setSearchTerm(e.target.value)}
                    showNoResults={false}
                    placeholder="Pretraži"
                />
            </div>

            <div className="filter-wrapper">
                <div>
                    <Dropdown
                        placeholder="Odaberi tip"
                        selection
                        options={[
                            { key: "all", text: "Sve", value: "" },
                            { key: "bijelo", text: "Bijelo", value: "Bijelo" },
                            { key: "crno", text: "Crno", value: "Crno" },
                        ]}
                        value={earningTypeFilter}
                        onChange={(e, { value }) => setEarningTypeFilter(value)}
                    />
                </div>
            </div>

            <Table celled className="custom-table">
                {data && data.getUserEarnings.length === 0 ? (
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                <Label>No data...</Label>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                ) : (
                    <Table.Header>
                        <Table.Row warning>
                            <Table.HeaderCell>Datum</Table.HeaderCell>
                            <Table.HeaderCell>Tip</Table.HeaderCell>
                            <Table.HeaderCell>Opis</Table.HeaderCell>
                            <Table.HeaderCell>Keš</Table.HeaderCell>
                            <Table.HeaderCell>Kartica</Table.HeaderCell>
                            <Table.HeaderCell>Ukupan PDV</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                )
                }

                <Table.Body>
                    {filteredData?.map(({
                        id,
                        date,
                        earningType,
                        description,
                        cash_total,
                        card_total,
                        tax_total
                    }) => (
                        (
                            <Table.Row key={id}>
                                <Table.Cell>
                                    <Label>{date}</Label>
                                </Table.Cell>
                                <Table.Cell>{earningType}</Table.Cell>
                                <Table.Cell>{description}</Table.Cell>
                                <Table.Cell>{cash_total} €</Table.Cell>
                                <Table.Cell>{card_total} €</Table.Cell>
                                <Table.Cell>{tax_total} €</Table.Cell>
                                <Table.Cell><Link to={`/earnings/${id}`}>Uredi</Link></Table.Cell>
                            </Table.Row>
                        )
                    ))}
                </Table.Body>
            </Table>

            <Link to="/create-earning">
                <p className="ui inverted primary button big add-button">
                    +
                </p>
            </Link>
        </>
    )
}

export default Earnings;