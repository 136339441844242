import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { useQuery, useMutation } from "@apollo/client";
import { GET_SPENDING_BY_ID, UPDATE_SPENDING, DELETE_SPENDING, GET_USER_SPENDINGS } from "../utils/graphqlQueries";
import { changeDateFormat } from "../utils/formatDate";
import { Header, Button } from "semantic-ui-react";
import './CreateSpending.css';

const SpendingEdit = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { register, setValue, handleSubmit } = useForm()
    const { data, loading, error } = useQuery(GET_SPENDING_BY_ID, {
        variables: { spendingId: id },
        fetchPolicy: "no-cache"
    })
    const [updateSpending] = useMutation(UPDATE_SPENDING)
    const [deleteSpending] = useMutation(DELETE_SPENDING)

    useEffect(() => {
        if (data) {
            setValue('spendingType', data.getUserSpendingById.spendingType)
            setValue('paymentType', data.getUserSpendingById.paymentType)
            setValue('date', changeDateFormat(data.getUserSpendingById.date))
            setValue('description', data.getUserSpendingById.description)
            setValue('regular_price', data.getUserSpendingById.regular_price)
            setValue('total_price', data.getUserSpendingById.total_price)
            setValue('tax', data.getUserSpendingById.tax)
        }
    }, [data, id])

    if (error) return <h2>Error: {error.message}</h2>
    if (loading) return <h2>Loading...</h2>

    const onSubmit = (formData) => {
        updateSpending({
            variables: { spendingId: id, spendingInput: { ...formData, date: changeDateFormat(formData.date) } },
            refetchQueries: [
                { query: GET_USER_SPENDINGS }
            ],
        }).then(() => navigate(-1))
    }

    const handleDelete = () => {
        deleteSpending({
            variables: { objectId: id },
            refetchQueries: [
                { query: GET_USER_SPENDINGS }
            ]
        })
        .then(() => navigate(-1))
    }

    return (
        <>
            <Header as='h3' dividing>
                Uredi Trošak
            </Header>

            <form className="spending-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                    <label htmlFor="spendingType">Tip:</label>
                    <select {...register('spendingType')} required>
                        <option value="Bijelo">Bijelo</option>
                        <option value="Crno">Crno</option>
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="paymentType">Način plaćanja:</label>
                    <select {...register('paymentType')} required>
                        <option value="Cash">Gotovinsko</option>
                        <option value="Card">Kartično</option>
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="date">Datum:</label>
                    <input type="date" {...register('date')} required />
                </div>

                <div className="form-group">
                    <label htmlFor="description">Opis:</label>
                    <input type="text" {...register('description')} required />
                </div>

                <div className="form-group">
                    <label htmlFor="regular_price">Osnovica:</label>
                    <input type="number" step="any" {...register('regular_price', { valueAsNumber: true })} required />
                </div>

                <div className="form-group">
                    <label htmlFor="total_price">Ukupno:</label>
                    <input type="number" step="any" {...register('total_price', { valueAsNumber: true })} required />
                </div>

                <div className="form-group">
                    <label htmlFor="tax">PDV:</label>
                    <input type="number" step="any" {...register('tax', { valueAsNumber: true })} required />
                </div>

                <div className='button-div'>
                    <button type="submit">Spremi</button>
                </div>
            </form>

            <div className="delete-button-wrapper">
                <Button negative onClick={handleDelete}>
                    Obrisi
                </Button>
            </div>
        </>
    )
}

export default SpendingEdit;