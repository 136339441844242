import { gql } from "@apollo/client";

export const GET_POSTS = gql`
{
    getPosts {
        id content username createdAt
        comments {
            content
            id
        }
        likes {
            username
            id
        }
        likesCount
        commentsCount
    }
}
`;

export const GET_POST = gql`
    query GetPost ($postId: ID!) {
        getPost(postId: $postId) {
            id content username createdAt
            comments {
                content
                username
                id
            }
            likes {
                username
                id
            }
            likesCount
            commentsCount
        }
    }
`;

export const GET_USER_EARNINGS = gql`
    query GetUserEarnings($date: String) {
        getUserEarnings(date: $date) {
            id
            earningType
            date
            description
            cash_total
            card_total
            tax_total
        }
    }
`;

export const GET_EARNING_BY_ID = gql`
    query GetUserEarningById($earningId: String) {
        getUserEarningById(earningId: $earningId) {
            id
            earningType
            date
            description
            cash_total
            card_total
            tax_total
        }
    }
`;

export const GET_USER_SPENDINGS = gql`
    query GetUserSpendings($date: String) {
        getUserSpendings(date: $date) {
            id
            spendingType
            date
            description
            regular_price
            total_price
            tax
            paymentType
        }
    }
`;

export const GET_SPENDING_BY_ID = gql`
    query GetUserSpendingById($spendingId: String) {
        getUserSpendingById(spendingId: $spendingId) {
            id
            spendingType
            date
            description
            regular_price
            total_price
            tax
            paymentType
        }
    }
`;

export const GET_UTRZAK_LIST = gql`
    query GetUtrzakList {
        getUtrzakList {
            id
            amount
            date
        }
    }
`;

export const GET_STATS = gql`
    query GetStats($date: String) {
        getStats(date: $date) {
            taxToPay
            utrzak
            totalEarningsBlack
            totalEarningsWhite
        }
    }
`;

export const CREATE_POST = gql`
    mutation createPost ($content: String!) {
        createPost(content: $content) {
            id content createdAt username likesCount
            commentsCount
            comments {
                id username content createdAt
            }
            likes {
                id username createdAt
            }
        }
    }
`;

export const CREATE_EARNING = gql`
    mutation CreateEarning($earningInput: EarningInput) {
        createEarning(earningInput: $earningInput) {
            earningType
            date
            description
            cash_total
            card_total
            tax_total
        }
    }
`;

export const UPDATE_EARNING = gql`
    mutation UpdateEarning($earningId: String, $earningInput: EarningInput) {
        updateEarning(earningId: $earningId, earningInput: $earningInput) {
            id
            earningType
            date
            description
            cash_total
            card_total
            tax_total
        }
    }
`;

export const CREATE_SPENDING = gql`
    mutation CreateSpending($spendingInput: SpendingInput!) {
        createSpending(spendingInput: $spendingInput) {
            spendingType
            date
            description
            regular_price
            total_price
            tax
        }
    }
`;

export const UPDATE_SPENDING = gql`
    mutation UpdateSpending($spendingId: String, $spendingInput: SpendingInput) {
        updateSpending(spendingId: $spendingId, spendingInput: $spendingInput) {
            id
            spendingType
            date
            description
            regular_price
            total_price
            tax
            paymentType
        }
    }
`;

export const ADD_UTRZAK = gql`
    mutation AddUtrzak($utrzakInput: UtrzakInput) {
        addUtrzak(utrzakInput: $utrzakInput) {
            paidUtrzak
        }
    }
`;

export const CREATE_COMMENT = gql`
    mutation CreateComment($postId: ID!, $content: String!) {
        createComment(postId: $postId, content: $content) {
            id
            commentsCount
            comments {
                id username createdAt content
            }
        }
    }
`;

export const DELETE_POST = gql`
    mutation deletePost ($postId: ID!) {
        deletePost(id: $postId)
    }
`;

export const DELETE_SPENDING = gql`
    mutation DeleteSpending($objectId: String) {
        deleteSpending(objectId: $objectId)
    }
`;

export const DELETE_EARNING = gql`
    mutation DeleteEarning($objectId: String) {
        deleteEarning(objectId: $objectId)
    }
`;

export const DELETE_COMMENT = gql`
    mutation DeleteComment($postId: ID!, $commentId: ID!) {
        deleteComment(postId: $postId, commentId: $commentId) {
            id 
            commentsCount
            comments {
                id username createdAt content
            }
        }
    }
`;

export const LOGIN_USER = gql`
    mutation Login($username: String!, $password: String!) {
        login( username: $username password: $password ) {
            id email username createdAt token
        }
    }
`;

export const REGISTER_USER = gql`
    mutation Register ($username: String! $email: String! $password: String! $confirmPassword: String!) {
        register(
            registerInput: {
                username: $username
                email: $email
                password: $password
                confirmPassword: $confirmPassword
            }
        ) {
            id email username createdAt token
        }
    }
`;

export const LIKE_POST = gql`
    mutation Login($postId: ID!) {
        likePost(postId: $postId) {
            id
            likesCount
            likes {
                username
                id
            }
        }
    }
`;
