
import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { AuthProvider } from './context/auth';
import AuthRoute from './utils/AuthRoute';

import Home from './components/Home';
import Login from './components/Login';
import MenuBar from './components/MenuBar';
import DetailPage from './components/PostDetail';
import Register from './components/Register';
import Spendings from './components/Spendings';
import Earnings from './components/Earnings';
import CreateSpending from './components/CreateSpending';
import CreateEarning from './components/CreateEarning';
import Stats from './components/Stats';
import SpendingEdit from './components/SpendingEdit';
import EarningEdit from './components/EarningEdit';
import UtrzakList from './components/UtrzakList';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Container>
          <MenuBar />
          <Routes>
            <Route exact path='/' element={<AuthRoute><Home /></AuthRoute>} />
            <Route exact path='/spendings' element={<AuthRoute><Spendings /></AuthRoute>} />
            <Route exact path='/spendings/:id' element={<AuthRoute><SpendingEdit /></AuthRoute>} />
            <Route exact path='/create-spending' element={<AuthRoute><CreateSpending /></AuthRoute>} />
            <Route exact path='/earnings' element={<AuthRoute><Earnings /></AuthRoute>} />
            <Route exact path='/earnings/:id' element={<AuthRoute><EarningEdit /></AuthRoute>} />
            <Route exact path='/create-earning' element={<AuthRoute><CreateEarning /></AuthRoute>} />
            <Route exact path='/stats' element={<AuthRoute> <Stats /></AuthRoute>} />
            <Route exact path='/utrzak-list' element={<AuthRoute> <UtrzakList /></AuthRoute>} />
            <Route exact path='/post/:postId' element={<AuthRoute><DetailPage /></AuthRoute>} />
            <Route path='/register' element={<AuthRoute><Register /></AuthRoute>} />
            <Route path='/login' element={<Login />} />
          </Routes>
        </Container>
      </Router>
    </AuthProvider>
  );
}

export default App;
