import React from 'react';
import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, Icon } from 'semantic-ui-react';
import { AuthContext } from '../context/auth';

export default function MenuBar() {
    const { pathname } = useLocation();
    const { user, logout } = useContext(AuthContext);

    return (
        <div>
            {user ? (
                <Menu size="massive" widths={5} color='teal' fixed='bottom' >
                    <Menu.Item
                        name={user?.username}
                        active={pathname === "/"}
                        as={Link}
                        to='/'
                    >
                        <Icon name='mail' />
                    </Menu.Item>
                    <Menu.Item
                        name="Spendings"
                        active={pathname === "/spendings"}
                        as={Link}
                        to='/spendings'
                    >
                        <Icon name='sort amount up' />
                    </Menu.Item>
                    <Menu.Item
                        name="Earnings"
                        active={pathname === "/earnings"}
                        as={Link}
                        to='/earnings'
                    >
                       <Icon name='sort amount down' /> 
                    </Menu.Item>
                    <Menu.Item
                        name="Stats"
                        active={pathname === "/stats"}
                        as={Link}
                        to='/stats'
                    >
                        <Icon name='chart bar' />
                    </Menu.Item>

                    <Menu.Menu position='right'>
                        <Menu.Item
                            name='logout'
                            active={pathname === '/logout'}
                            onClick={logout}
                            as={Link}
                            to='/login'
                        >
                            <Icon name='power off' />
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
            ) : (
                <Menu pointing secondary size="massive" color='teal' >
                    <Menu.Menu position='right'>
                        <Menu.Item
                            name='login'
                            active={pathname === '/login'}
                            as={Link}
                            to='/login'
                        />
                    </Menu.Menu>
                </Menu>
            )}
        </div>
    );
}