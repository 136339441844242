import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import hr from 'date-fns/locale/hr';
import { useQuery, useMutation } from "@apollo/client";
import { ADD_UTRZAK, GET_STATS } from "../utils/graphqlQueries";
import { formatDate, changeDateFormat } from "../utils/formatDate";
import {
    CardGroup,
    Card,
    ModalHeader,
    ModalContent,
    ModalActions,
    Button,
    Modal,
    Loader,
    CardHeader,
    CardContent
} from 'semantic-ui-react'

const Stats = () => {
    const navigate = useNavigate()
    const [startDate, setStartDate] = useState(new Date());
    const [isOpen, setIsOpen] = useState(false)
    const [value, setValue] = useState(null)
    const [date, setDate] = useState(null)
    const { data, loading, error } = useQuery(GET_STATS, {
        variables: { date: formatDate(startDate) },
        fetchPolicy: "cache-and-network"
    })
    const [addUtrzak, { loading: load }] = useMutation(ADD_UTRZAK)

    const handleAddingUtrzak = () => {
        if (value && date) {
            addUtrzak({
                variables: { utrzakInput: { date: changeDateFormat(date), amount: Number(value) } },
                refetchQueries: [GET_STATS]
            })
                .finally(() => {
                    setIsOpen(false)
                    setValue(null)
                })
        }
    }

    if (loading || load) return <Loader active inline='centered' />

    return (
        <>
            <div className="datepicker-div">
                <DatePicker
                    locale={hr}
                    dateFormat="MM/yyyy"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    showMonthYearPicker
                    showFullMonthYearPicker
                />
            </div>

            {!data?.getStats && <h2 style={{ textAlign: "center" }}>No data..</h2>}

            {data && data.getStats &&
                <CardGroup>
                    <Card
                        fluid
                        color='teal'
                        header={`Porez za platit: ${data.getStats.taxToPay.toFixed(2)} €`}
                    />
                    <Card
                        fluid
                        color='teal'
                    >
                        <CardContent>
                            <CardHeader>
                                Utržak: {data.getStats.utrzak.toFixed(2)}
                            </CardHeader>
                        </CardContent>
                        <CardContent>
                            <Button
                                basic
                                color='teal'
                                onClick={() => navigate("/utrzak-list")}
                            >
                                Vidi sve utržke
                            </Button>
                        </CardContent>
                    </Card>
                    <Card
                        fluid
                        color='teal'
                        header={`Ukupna zarada (crno): ${data.getStats.totalEarningsBlack.toFixed(2)} €`}
                    />
                    <Card
                        fluid
                        color='teal'
                        header={`Ukupna zarada (bijelo): ${data.getStats.totalEarningsWhite.toFixed(2)} €`}
                    />
                </CardGroup>
            }

            <Modal
                size="small"
                open={isOpen}
                onClose={() => setIsOpen(false)}
            >
                <ModalHeader>Dodaj utržak.</ModalHeader>
                <ModalContent>
                    <p>Unesi količinu utržka u eur:</p>
                    <input type="number" step="any" onChange={(e) => setValue(e.target.value)} />
                    <input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
                </ModalContent>
                <ModalActions>
                    <Button negative onClick={() => setIsOpen(false)}>
                        Odustani
                    </Button>
                    <Button positive onClick={handleAddingUtrzak}>
                        Potvrdi
                    </Button>
                </ModalActions>
            </Modal>


            {data && data.getStats &&
                <p
                    className="ui inverted primary button big add-button"
                    onClick={() => setIsOpen(true)}
                >
                    + UTRŽAK
                </p>
            }
        </>
    )
}

export default Stats;